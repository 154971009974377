import { AppEpic, camelToSnakeCase, getAuthHeaders } from "./index";
import { combineEpics, ofType } from "redux-observable";
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from "rxjs/operators";
import { from, of, race, throwError, timer } from "rxjs";
import { getCurrentUser } from "../../components/services/api";
import {
    checkRegistrationStatusFailure,
    checkRegistrationStatusRequest, checkRegistrationStatusSuccess,
    createRegistrationSessionFailure,
    createRegistrationSessionRequest,
    createRegistrationSessionSuccess,
    getRegistrationSessionFailure,
    getRegistrationSessionRequest,
    getRegistrationSessionSuccess, LocalStepType, RegistrationState, StepType, updateLocalRegistrationSession,
    updateRegistrationSessionFailure,
    updateRegistrationSessionRequest,
    updateRegistrationSessionSuccess
} from "../registrationSlice";
import { mapUserPayloadToUser, signInSuccess, TokenData } from "../userSlice";
import { handleApiError, handleEpicError } from "./errorHelpers";

/**
 * Maps the API response to the RegistrationState structure
 * @param data - The API response containing registration session details
 * @returns {RegistrationState} - The mapped registration state
 */
const mapApiResponseToRegistrationState = (data: any): RegistrationState => ({
    sessionId: data.session_id ?? null,
    step: (data.step as StepType) ?? null,
    localStep: (data.step as LocalStepType) ?? null,
    email: data.email ?? null,
    planId: data.plan_id ?? null,
    billingFrequency: data.billing_frequency ?? null,
    clientSecret: data.client_secret ?? null,
    paymentIntentId: data.payment_intent_id ?? null,
    totalAmount: data.total_amount ?? null,
    totalAmountDecimal: data.total_amount_decimal ?? null,
    currency: data.currency ?? null,
    createdAt: data.created_at ?? null,
    updatedAt: data.updated_at ?? null,
    lineItems: data.line_items ?? [],
    loading: false,
    error: null,
    couponCode: null,
    isApplyingCoupon: false
});

/**
 * Epic to handle checking the registration status at regular intervals
 * @param action$ - Stream of actions dispatched to Redux
 * @returns Observable - Streams of actions or results
 */
export const checkRegistrationStatusEpic: AppEpic = (action$) => action$.pipe(
    ofType(checkRegistrationStatusRequest.type),
    switchMap((action) =>
        timer(0, 5000).pipe(
            mergeMap(() =>
                from(fetch(`/api/registration_status/${action.payload}`, {
                    method: 'GET',
                    headers: getAuthHeaders(),
                })).pipe(
                    mergeMap(response =>
                        response.ok
                            ? from(response.json())
                            : throwError(() => new Error(`HTTP error! status: ${response.status}`))
                    ),
                    tap(data => console.log('Registration status response:', data)),
                    mergeMap(data => {
                        if (data.status === 'completed') {
                            const tokens: TokenData = {
                                access_token: data.access_token,
                                refresh_token: data.refresh_token,
                                expires_in: data.expires_in,
                                token_type: 'Bearer',
                                created_at: Math.floor(Date.now() / 1000)
                            };

                            localStorage.setItem('tokens', JSON.stringify(tokens));

                            return from(getCurrentUser()).pipe(
                                tap(userData => console.log('User data fetched:', userData)),
                                mergeMap(userData => {
                                    console.log('Creating actions with user data:', userData);
                                    // Dispatch actions
                                    const actions = of(
                                        checkRegistrationStatusSuccess({
                                            status: 'completed',
                                            user: mapUserPayloadToUser(userData),
                                            accessToken: data.access_token,
                                            refreshToken: data.refresh_token,
                                            expiresIn: data.expires_in
                                        }),
                                        signInSuccess({ ...userData.user, tokens })
                                    );

                                    // TODO: this is a hack because we haven't quite figured out why
                                    // dispatching signInSuccess() isn't working as expected, but we know
                                    // that page refresh will work
                                    // Schedule a page reload after a short delay
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 100);  // 100ms delay to ensure actions are dispatched

                                    return actions;
                                })
                            );
                        }
                        return of(checkRegistrationStatusSuccess({
                            status: data.status,
                            user: mapUserPayloadToUser(data.user),
                            accessToken: data.access_token,
                            refreshToken: data.refresh_token,
                            expiresIn: data.expires_in
                        }));
                    }),
                    catchError(error => {
                        console.error('Error in checkRegistrationStatusEpic:', error);
                        return of(checkRegistrationStatusFailure(`Failed to check registration status: ${error.message}`));
                    })
                )
            ),
            takeUntil(race(
                action$.pipe(ofType(checkRegistrationStatusSuccess.type)),
                timer(180000) // 3 minutes timeout
            ))
        )
    )
);;

/**
 * Epic to handle creating a new registration session
 * @param action$ - Stream of actions dispatched to Redux
 * @returns Observable - Streams of actions or results
 */
export const createRegistrationSessionEpic: AppEpic = (action$) => action$.pipe(
    ofType(createRegistrationSessionRequest.type),
    switchMap(() =>
        from(fetch('/api/registrations', {
            method: 'POST',
            headers: {
                ...getAuthHeaders(),
                'Content-Type': 'application/json',
            },
        })).pipe(
            mergeMap(response =>
                response.ok
                    ? from(response.json())
                    : Promise.reject(new Error(`HTTP error! status: ${response.status}`))
            ),
            map(data => createRegistrationSessionSuccess(mapApiResponseToRegistrationState(data))),
            catchError(error => of(createRegistrationSessionFailure(`Failed to create registration session: ${error.message}`)))
        )
    )
);

/**
 * Epic to handle updating a registration session with new data
 * @param action$ - Stream of actions dispatched to Redux
 * @returns Observable - Streams of actions or results
 */
export const updateRegistrationSessionEpic: AppEpic = (action$) => action$.pipe(
    ofType(updateRegistrationSessionRequest.type),
    switchMap((action) => {
        if (action.payload.step || action.payload.planId || action.payload.billingFrequency) {
            const snakeCasePayload = camelToSnakeCase(action.payload);
            return from(fetch(`/api/registrations/${action.payload.sessionId}`, {
                method: 'PUT',
                headers: {
                    ...getAuthHeaders(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(snakeCasePayload),
            })).pipe(
                mergeMap(response => response.ok ?
                    from(response.json()) :
                    from(handleApiError(response))),
                map(data => updateRegistrationSessionSuccess(mapApiResponseToRegistrationState(data))),
                catchError(error => handleEpicError(error, updateRegistrationSessionFailure))
            );
        }
        return of(updateLocalRegistrationSession(action.payload));
    })
);

/**
 * Epic to handle fetching an existing registration session from the server
 * @param action$ - Stream of actions dispatched to Redux
 * @returns Observable - Streams of actions or results
 */
export const getRegistrationSessionEpic: AppEpic = (action$) => action$.pipe(
    ofType(getRegistrationSessionRequest.type),
    switchMap((action) =>
        from(fetch(`/api/registrations/${action.payload}`, {
            method: 'GET',
            headers: getAuthHeaders(),
        })).pipe(
            mergeMap(response => {
                if (response.ok) {
                    return from(response.json());
                } else if (response.status === 404) {
                    // Session doesn't exist, trigger creation of a new one
                    return of(createRegistrationSessionRequest());
                } else {
                    return Promise.reject(new Error(`HTTP error! status: ${response.status}`));
                }
            }),
            map(data => {
                if ('type' in data && data.type === createRegistrationSessionRequest.type) {
                    return data; // Return the createRegistrationSessionRequest action
                }
                return getRegistrationSessionSuccess(mapApiResponseToRegistrationState(data));
            }),
            catchError(error => of(getRegistrationSessionFailure(`Failed to fetch registration session: ${error.message}`)))
        )
    )
);

/**
 * Combines all registration-related epics into one exportable epic
 */
export const registrationEpics = combineEpics(
    checkRegistrationStatusEpic,
    createRegistrationSessionEpic,
    updateRegistrationSessionEpic,
    getRegistrationSessionEpic
);
