import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Tag } from 'lucide-react';
import { useAppDispatch, useAppSelector } from '@/store/reduxHooks';
import { setCouponCode, updateRegistrationSessionRequest } from '@/store/registrationSlice';
import InlineLoadingIndicator from '@/components/common/InlineLoadingIndicator';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

interface PaymentStepProps {
    clientSecret: string;
    paymentIntentId: string;
    currency: string;
    amountTotal: number;
    onPaymentSuccess: () => void;
    onPaymentError: (error: string) => void;
    onBack: () => void;
}

const FormButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: 'primary' | 'secondary' }> = ({
    children,
    variant = 'primary',
    ...props
}) => (
    <button
        {...props}
        className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50 ${variant === 'primary'
            ? 'text-white bg-teal-600 hover:bg-teal-700'
            : 'text-teal-700 bg-teal-100 hover:bg-teal-200'
            }`}
    >
        {children}
    </button>
);

const PaymentStep: React.FC<PaymentStepProps> = ({
    onPaymentSuccess,
    onPaymentError,
    onBack
}) => {
    const dispatch = useAppDispatch();
    const {
        sessionId,
        clientSecret,
        paymentIntentId,
        currency,
        totalAmount,
        lineItems,
        couponCode,
        planId,
        billingFrequency,
        isApplyingCoupon,
    } = useAppSelector((state) => state.registration);

    const stripe = useStripe();
    const elements = useElements();
    const [localCouponCode, setLocalCouponCode] = useState(couponCode || '');

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!stripe || !elements || !clientSecret) {
            return;
        }

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)!,
            }
        });

        if (result.error) {
            onPaymentError(result.error.message!);
        } else {
            onPaymentSuccess();
        }
    };

    const handleApplyCoupon = async () => {
        if (!sessionId || !planId || !billingFrequency || !localCouponCode) return;

        try {
            await dispatch(updateRegistrationSessionRequest({
                sessionId,
                planId,
                billingFrequency,
                couponCode: localCouponCode,
                step: 'plan_selection'
            })).unwrap();

            dispatch(setCouponCode(localCouponCode));
        } catch (error) {
            if (error instanceof Error) {
                onPaymentError(error.message);
            }
        }
    };

    const handleCouponInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalCouponCode(e.target.value);
    };

    const formatAmount = (amount: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency?.toUpperCase() || 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount / 100);
    };

    return (
        <div className="space-y-6">
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Order Summary</h3>

                <div className="space-y-3">
                    {lineItems.map((item, index) => (
                        <div key={index} className="flex justify-between text-sm">
                            <span className="text-gray-600">{item.description}</span>
                            <span className="text-gray-900 font-medium">
                                {formatAmount(item.amount)}
                            </span>
                        </div>
                    ))}

                    <div className="pt-3 border-t border-gray-200">
                        <div className="flex flex-col space-y-2">
                            <div className="flex space-x-2">
                                <div className="flex-1">
                                    <input
                                        type="text"
                                        value={localCouponCode}
                                        onChange={handleCouponInputChange}
                                        placeholder="Enter coupon code"
                                        disabled={isApplyingCoupon}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm focus:ring-teal-500 focus:border-teal-500 disabled:bg-gray-100 disabled:cursor-not-allowed"
                                    />
                                </div>
                                <button
                                    onClick={handleApplyCoupon}
                                    disabled={!localCouponCode || localCouponCode === couponCode || isApplyingCoupon}
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
                                >
                                    <span className="flex items-center">
                                        <Tag className="w-4 h-4 mr-2" />
                                        Apply
                                    </span>
                                </button>
                            </div>
                            {isApplyingCoupon && <InlineLoadingIndicator />}
                        </div>
                    </div>

                    <div className="pt-3 border-t border-gray-200">
                        <div className="flex justify-between">
                            <span className="text-base font-medium text-gray-900">Total</span>
                            <span className="text-base font-medium text-gray-900">
                                {formatAmount(totalAmount || 0)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Card Details
                    </label>
                    <div className="border border-gray-300 rounded-md p-4 bg-white">
                        <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </div>
                </div>

                <div className="flex space-x-4">
                    <FormButton type="button" onClick={onBack} variant="secondary">
                        Back
                    </FormButton>
                    <FormButton type="submit" disabled={!stripe}>
                        Pay {formatAmount(totalAmount || 0)}
                    </FormButton>
                </div>
            </form>
        </div>
    );
};

export default PaymentStep;
