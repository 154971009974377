import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Shield, CheckCircle, CreditCard, Bell, Battery, Download } from 'lucide-react';
import { addWeeks } from 'date-fns';
import {
    createRegistrationSessionRequest,
    updateRegistrationSessionRequest,
    updateLocalRegistrationSession,
    getRegistrationSessionRequest,
    setLocalStep,
    RegistrationState
} from '../../store/registrationSlice';
import { EmailPasswordStep } from "./SignupForm/EmailPasswordStep";
import { PlanSelectionStep } from "./SignupForm/PlanSelectionStep";
import { PaymentStepWrapper } from "./SignupForm/PaymentStepWrapper";
import LoadingScreen from "./LoadingScreen";
import AuthLayout from "../layout/AuthLayout";
import { BillingFrequency } from '@/store/subscriptionSlice';
import ProgressIndicator from './SignupForm/ProgressIndicator';

interface RootState {
    registration: RegistrationState;
    user: {
        isAuthenticated: boolean;
    };
}

interface MessageBoxProps {
    title?: string;
    children: React.ReactNode;
    icon?: React.ElementType;
    variant?: 'info' | 'success' | 'warning';
}

const MessageBox: React.FC<MessageBoxProps> = ({ title, children, icon: Icon, variant = 'info' }) => {
    const styles = {
        info: 'bg-blue-50 border-blue-200 text-blue-800',
        success: 'bg-teal-50 border-teal-200 text-teal-800',
        warning: 'bg-yellow-50 border-yellow-200 text-yellow-800'
    };

    return (
        <div className={`p-4 rounded-lg border ${styles[variant]}`}>
            <div className="flex items-start space-x-3">
                {Icon && <Icon className="w-5 h-5 mt-0.5" />}
                <div>
                    {title && <h4 className="font-medium mb-1">{title}</h4>}
                    <div className="text-sm opacity-90">{children}</div>
                </div>
            </div>
        </div>
    );
};
const TrustIndicators: React.FC = () => (
    <div className="mt-8 pt-6 border-t border-gray-200">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-start space-x-3">
                <Shield className="w-5 h-5 text-teal-600 mt-0.5" />
                <div>
                    <h4 className="text-sm font-medium text-gray-900">Privacy By Design</h4>
                    <p className="text-sm text-gray-500">No location tracking, minimal data collection</p>
                </div>
            </div>
            <div className="flex items-start space-x-3">
                <Bell className="w-5 h-5 text-teal-600 mt-0.5" />
                <div>
                    <h4 className="text-sm font-medium text-gray-900">Smart Notifications</h4>
                    <p className="text-sm text-gray-500">Timely alerts without compromising privacy</p>
                </div>
            </div>
        </div>
    </div>
);

const SignUpForm: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        sessionId,
        step,
        localStep,
        email,
        planId,
        billingFrequency,
        clientSecret,
        paymentIntentId,
        currency,
        totalAmount,
        updatedAt,
        loading,
        error
    } = useSelector((state: RootState) => state.registration);

    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);

    const [password, setPassword] = useState('');
    const [initialEmail, setInitialEmail] = useState(email || '');

    useEffect(() => {
        const checkAndUpdateSession = () => {
            if (!sessionId) {
                dispatch(createRegistrationSessionRequest());
            } else if (updatedAt) {
                const lastUpdateDate = new Date(updatedAt * 1000);
                const oneWeekAgo = addWeeks(new Date(), -1);

                if (lastUpdateDate < oneWeekAgo) {
                    dispatch(createRegistrationSessionRequest());
                } else {
                    dispatch(getRegistrationSessionRequest(sessionId));
                }
            } else {
                dispatch(getRegistrationSessionRequest(sessionId));
            }
        };

        checkAndUpdateSession();
    }, [dispatch, sessionId, updatedAt]);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (email && !initialEmail) {
            setInitialEmail(email);
        }
    }, [email, initialEmail]);

    const handleEmailChange = useCallback((newEmail: string) => {
        dispatch(updateLocalRegistrationSession({ email: newEmail }));
    }, [dispatch]);

    const handleEmailPasswordSubmit = (submittedEmail: string, submittedPassword: string) => {
        setPassword(submittedPassword);
        if (sessionId) {
            dispatch(updateRegistrationSessionRequest({
                sessionId,
                email: submittedEmail,
                password: submittedPassword,
                step: 'personal_info'
            }));
        }
    };

    const handlePaymentStart = () => {
        dispatch(setLocalStep('payment_processing'));
    };

    const handlePaymentSuccess = () => {
        if (sessionId) {
            dispatch(updateRegistrationSessionRequest({
                sessionId,
                step: 'completed'
            }));
        }
        dispatch(setLocalStep('confirmation'));
    };

    const handlePaymentError = (error: string) => {
        dispatch(setLocalStep('payment'));
    };

    const getStepTitle = (): string => {
        switch (localStep) {
            case 'personal_info': return "Create your account";
            case 'plan_selection': return "Choose your plan";
            case 'payment': return "Payment details";
            case 'payment_processing': return "Processing payment...";
            case 'confirmation': return "Payment confirmed";
            case 'completed': return "Registration completed";
            default: return "Sign Up";
        }
    };

    const renderStepContent = (): React.ReactNode => {
        if (!localStep) return null;

        switch (localStep) {
            case 'initiated':
            case 'personal_info':
                return (
                    <div className="space-y-6">
                        <MessageBox
                            title="Stop Paying Overstay Fees"
                            icon={Download}
                            variant="info"
                        >
                            Join Tesla owners who are saving money and time with smart charging alerts.
                        </MessageBox>

                        <EmailPasswordStep
                            email={email || ''}
                            setEmail={handleEmailChange}
                            password={password}
                            setPassword={setPassword}
                            onNext={handleEmailPasswordSubmit}
                            initialEmail={initialEmail}
                            remoteStep={step}
                        />
                        <TrustIndicators />
                    </div>
                );

            case 'plan_selection':
                return (
                    <div className="space-y-6">
                        <MessageBox
                            title="Simple Solution to Overstay Fees"
                            icon={CheckCircle}
                            variant="success"
                        >
                            Smart alerts that help Tesla owners save money and time. Never worry about charging fees again.
                        </MessageBox>

                        <PlanSelectionStep
                            sessionId={sessionId}
                            selectedPlanId={planId || ''}
                            selectedBillingFrequency={billingFrequency || BillingFrequency.Monthly}
                            onNext={() => dispatch(setLocalStep('payment'))}
                            onBack={() => dispatch(setLocalStep('personal_info'))}
                        />

                        <div className="bg-teal-50 p-6 rounded-xl border-2 border-teal-100">
                            <h3 className="text-lg font-semibold text-gray-800 mb-4">
                                Our Privacy Commitment
                            </h3>
                            <div className="space-y-3">
                                {[
                                    "No location tracking required",
                                    "Minimal data collection",
                                    "Secure charging status monitoring",
                                    "Local notification processing"
                                ].map((commitment: string, index: number) => (
                                    <div key={index} className="flex items-center space-x-2">
                                        <CheckCircle className="w-5 h-5 text-teal-600" />
                                        <span className="text-gray-700">{commitment}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );

            case 'payment':
            case 'payment_processing':
                return clientSecret && paymentIntentId && currency && totalAmount && sessionId ? (
                    <div className="space-y-6">
                        <MessageBox
                            title="Secure Payment Processing"
                            icon={CreditCard}
                            variant="info"
                        >
                            {localStep === 'payment_processing'
                                ? 'Processing your payment securely...'
                                : 'Your payment will be processed securely through Stripe.'}
                        </MessageBox>

                        <PaymentStepWrapper
                            sessionId={sessionId}
                            onPaymentStart={handlePaymentStart}
                            onPaymentSuccess={handlePaymentSuccess}
                            onPaymentError={handlePaymentError}
                            onBack={() => dispatch(setLocalStep('plan_selection'))}
                            isProcessing={localStep === 'payment_processing'}
                        />
                        <TrustIndicators />
                    </div>
                ) : null;

            case 'confirmation':
            case 'completed':
                return (
                    <div className="text-center space-y-4">
                        <div className="w-16 h-16 mx-auto bg-teal-100 rounded-full flex items-center justify-center">
                            <CheckCircle className="w-8 h-8 text-teal-600" />
                        </div>
                        <h3 className="text-xl font-medium text-gray-900">
                            {localStep === 'confirmation' ? 'Welcome to EVAlarm!' : 'Setup Complete!'}
                        </h3>
                        <p className="text-gray-600">
                            {localStep === 'confirmation'
                                ? 'Setting up your smart charging alerts...'
                                : 'Taking you to your dashboard...'}
                        </p>
                        <LoadingScreen />
                    </div>
                );

            default:
                return null;
        }
    };

    if (loading && (!localStep || !['payment', 'payment_processing', 'confirmation', 'completed'].includes(localStep))) {
        return <LoadingScreen />;
    }

    return (
        <AuthLayout
            title={getStepTitle()}
            subtitle="Start saving on charging fees today"
        >
            <div className="max-w-2xl mx-auto">
                <ProgressIndicator currentStep={localStep} />
                {renderStepContent()}
                {error && (
                    <MessageBox variant="warning" title="Error">
                        {error}
                    </MessageBox>
                )}
            </div>
        </AuthLayout>
    );
};

export default SignUpForm;
