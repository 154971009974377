import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, MessageSquare, Send } from 'lucide-react';
import { AppDispatch, RootState } from "../../../store";
import {
    createCommentRequest,
    fetchTicketRequest,
    getHumanReadableStatus,
    TicketStatus,
} from "../../../store/helpdeskSlice";
import { formatDateInWords } from "../../services/util";

const TicketDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const { currentTicket, loading, error } = useSelector((state: RootState) => state.helpdesk);
    const [newComment, setNewComment] = useState('');

    useEffect(() => {
        if (id) {
            dispatch(fetchTicketRequest(id));
        }
    }, [dispatch, id]);

    const handleAddComment = () => {
        if (newComment.trim() && currentTicket) {
            dispatch(createCommentRequest({ ticketId: currentTicket.id, body: newComment }));
            setNewComment('');
        }
    };

    const getStatusColor = (status: TicketStatus) => {
        switch (status) {
            case TicketStatus.OPEN:
                return 'bg-green-100 text-green-800';
            case TicketStatus.IN_PROGRESS:
                return 'bg-yellow-100 text-yellow-800';
            case TicketStatus.CLOSED:
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    if (loading) return <div className="flex justify-center items-center h-64"><div className="animate-spin rounded-full h-32 w-32 border-b-2 border-teal-500"></div></div>;
    if (error) return <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert"><strong className="font-bold">Error!</strong> <span className="block sm:inline">{error}</span></div>;
    if (!currentTicket) return <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert"><p className="font-bold">Not Found</p><p className="text-sm">Ticket not found</p></div>;

    return (
        <div className="space-y-6 max-w-4xl mx-auto">
            <Link to="/help/ticket" className="inline-flex items-center text-teal-600 hover:text-teal-800 transition-colors">
                <ArrowLeft className="h-5 w-5 mr-1" />
                Back to Tickets
            </Link>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="px-6 py-4 bg-gradient-to-r from-teal-500 to-teal-600">
                    <h2 className="text-2xl font-bold text-white">{currentTicket.title}</h2>
                    <p className="text-teal-100">Ticket ID: {currentTicket.id}</p>
                </div>
                <div className="p-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(currentTicket.status)}`}>
                                    {getHumanReadableStatus(currentTicket.status)}
                                </span>
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Created</dt>
                            <dd className="mt-1 text-sm text-gray-900">{formatDateInWords(currentTicket.created_at)}</dd>
                        </div>
                        <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">Description</dt>
                            <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{currentTicket.description}</dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="px-6 py-4 bg-gray-50 border-b">
                    <h3 className="text-xl font-semibold flex items-center">
                        <MessageSquare className="h-5 w-5 mr-2 text-teal-500" />
                        Comments
                    </h3>
                </div>
                <div className="p-6">
                    {currentTicket.comments.length === 0 ? (
                        <p className="text-gray-600 italic">No comments yet.</p>
                    ) : (
                        <ul className="space-y-4">
                            {currentTicket.comments.map((comment) => (
                                <li key={comment.id} className="bg-gray-50 p-4 rounded-lg">
                                    <p className="text-sm text-gray-600 mb-2">
                                        <span className="font-medium">{comment.author}</span>
                                        <span className="mx-2">•</span>
                                        {formatDateInWords(comment.created_at)}
                                    </p>
                                    <p className="text-gray-900 whitespace-pre-wrap">{comment.body}</p>
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className="mt-6">
                        <textarea
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Add a comment..."
                            rows={3}
                            className="w-full p-2 border rounded-md"
                        />
                        <div className="mt-2 flex justify-end">
                            <button
                                onClick={handleAddComment}
                                disabled={!newComment.trim()}
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <Send className="h-4 w-4 mr-2" />
                                Send Comment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketDetails;
