/**
 * @fileoverview Provides utilities for handling API errors and presenting user-friendly messages
 * in a React/Redux application using Redux-Observable epics.
 */

import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';

/**
 * Standard structure for API error responses
 */
interface ApiErrorResponse {
  error_message?: string;
  message?: string;
  errors?: string[];
  status: number;
}

/**
 * Patterns for error messages that should be displayed directly to users
 * rather than being replaced with generic system messages.
 * These typically represent validation or business logic errors.
 */
const DISPLAY_ACTUAL_MESSAGE_PATTERNS = [
  /invalid.*(code|coupon)/i,    // Invalid coupon/code errors
  /expired.*(code|coupon)/i,    // Expired coupon/code errors
  /already.*exists/i,           // Duplicate entity errors
  /not.*found/i,               // Not found errors
  /incorrect.*password/i,       // Authentication errors
  /invalid.*credentials/i       // Authentication errors
];

/**
 * Generic system error messages mapped to HTTP status codes.
 * Used when the actual error message shouldn't be shown to users.
 */
const SYSTEM_ERROR_MESSAGES: Record<number, string> = {
  400: 'Please check your information and try again.',
  401: 'Your session has expired. Please sign in again.',
  403: 'Access denied. Please check your permissions.',
  404: 'The requested resource could not be found.',
  429: 'Too many attempts. Please wait a moment and try again.',
  500: 'Something went wrong on our end. Please try again later.',
  503: 'Service temporarily unavailable. Please try again soon.'
};

/**
 * Determines if the error message should be displayed directly to the user
 * based on predefined patterns.
 * 
 * @param message - The technical error message to check
 * @returns boolean indicating if the message should be shown to users
 */
const shouldDisplayActualMessage = (message: string): boolean =>
  DISPLAY_ACTUAL_MESSAGE_PATTERNS.some(pattern => pattern.test(message));

/**
 * Generates a user-friendly error message based on HTTP status and technical message.
 * In development, includes technical details in parentheses.
 * 
 * @param status - HTTP status code
 * @param technicalMessage - Original error message from the API
 * @returns Formatted user-friendly message
 */
export const getErrorMessage = (status: number, technicalMessage: string): string => {
  if (shouldDisplayActualMessage(technicalMessage)) {
    return technicalMessage.charAt(0).toUpperCase() + technicalMessage.slice(1);
  }

  if (process.env.NODE_ENV === 'development') {
    return `${SYSTEM_ERROR_MESSAGES[status] || 'An unexpected error occurred.'} (${technicalMessage})`;
  }

  return SYSTEM_ERROR_MESSAGES[status] || 'An unexpected error occurred. Please try again.';
};

/**
 * Handles API errors by attempting to parse the response and generate
 * appropriate error information for both logging and user display.
 * 
 * @param response - Fetch Response object from failed API call
 * @throws Error with structured error information
 */
export const handleApiError = async (response: Response): Promise<never> => {
  const status = response.status;
  let technicalMessage = '';

  try {
    const contentType = response.headers.get('content-type');
    if (contentType?.includes('application/json')) {
      const errorData = await response.json();
      technicalMessage = extractTechnicalError(errorData);
    } else {
      technicalMessage = await response.text() || `HTTP ${status}`;
    }
  } catch {
    technicalMessage = `Server error (${status})`;
  }

  console.error(`API Error (${status}):`, technicalMessage);

  throw new Error(JSON.stringify({
    userMessage: getErrorMessage(status, technicalMessage),
    technicalMessage,
    status,
    timestamp: new Date().toISOString()
  }));
};

/**
 * Extracts error message from various API error response formats
 * 
 * @param errorData - Raw error data from API
 * @returns Extracted error message string
 */
const extractTechnicalError = (errorData: unknown): string => {
  if (typeof errorData === 'string') return errorData;

  const error = errorData as ApiErrorResponse;
  return error.error_message ||
    error.message ||
    (error.errors?.join(', ')) ||
    'Unknown error';
};

/**
 * Creates an Observable that handles errors in Redux-Observable epics.
 * Parses structured error information and dispatches appropriate error actions.
 * 
 * @param error - The caught error
 * @param actionCreator - Redux action creator for error state
 * @returns Observable that emits error action
 * 
 * @example
 * ```typescript
 * catchError(error => handleEpicError(error, updateRegistrationSessionFailure))
 * ```
 */
export const handleEpicError = <T extends Action>(
  error: unknown,
  actionCreator: (message: string) => T,
): Observable<T> => {
  try {
    const parsedError = JSON.parse((error as Error).message);
    return new Observable(subscriber => {
      subscriber.next(actionCreator(parsedError.userMessage));
      subscriber.complete();
    });
  } catch {
    return new Observable(subscriber => {
      subscriber.next(actionCreator('An unexpected error occurred. Please try again.'));
      subscriber.complete();
    });
  }
};
