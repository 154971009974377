import React from 'react';
import { LocalStepType, StepType } from '@/store/registrationSlice';

interface ProgressIndicatorProps {
  currentStep: LocalStepType | null;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ currentStep }) => {
  const steps: Array<{ id: StepType; label: string }> = [
    { id: 'personal_info', label: 'Account' },
    { id: 'plan_selection', label: 'Plan' },
    { id: 'payment', label: 'Payment' }
  ];

  const getCurrentStepIndex = () => {
    return steps.findIndex(step => step.id === currentStep);
  };

  const currentIndex = getCurrentStepIndex();

  return (
    <div className="mb-8">
      <div className="relative">
        {/* Base progress bar */}
        <div className="absolute top-4 left-0 w-full h-1 bg-gray-200 rounded-full" />

        {/* Active progress bar */}
        <div
          className="absolute top-4 left-0 h-1 bg-teal-600 rounded-full transition-all duration-300 ease-in-out"
          style={{
            width: `${currentIndex >= 0 ? (currentIndex / (steps.length - 1)) * 100 : 0}%`
          }}
        />

        {/* Steps */}
        <div className="relative flex justify-between">
          {steps.map((step, idx) => {
            const isActive = currentStep === step.id;
            const isCompleted = idx < currentIndex;

            return (
              <div key={step.id} className="flex flex-col items-center">
                <div className={`
                                    w-8 h-8 rounded-full 
                                    flex items-center justify-center 
                                    border-2 transition-all duration-300
                                    ${isCompleted
                    ? 'border-teal-600 bg-teal-600 text-white'
                    : isActive
                      ? 'border-teal-600 bg-teal-50 text-teal-600'
                      : 'border-gray-300 bg-white text-gray-500'
                  }
                                `}>
                  {idx + 1}
                </div>
                <span className={`
                                    mt-2 text-sm
                                    ${isActive ? 'text-teal-600 font-medium' : 'text-gray-500'}
                                `}>
                  {step.label}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProgressIndicator;
